import React, { createContext } from 'react';

import {
  useAuth,
  isCustomTokenURL,
  isCustomSignOutURL,
} from './firebase-auth-provider';

import { navigate } from 'gatsby';

import LoadingScreen from '../components/shared/loading-screen';

export const HijackContext = createContext();

export const HijackProvider = ({ children }) => {
  const auth = useAuth();

  const { synchronized, error, hijack } = auth;

  let preventChildrenFromRendering = false;

  if (hijack.current) {
    preventChildrenFromRendering = true;
  } else if (hijack.initial) {
    if (isCustomTokenURL()) {
      if (!synchronized || error) {
        preventChildrenFromRendering = true;
      }
    } else if (isCustomSignOutURL() && auth.user) {
      preventChildrenFromRendering = true;
    }
  }

  if (preventChildrenFromRendering) {
    if (error) {
      navigate(`/401?code=${error.code}`);
    }

    return <LoadingScreen text="Please wait while we sign you in..." />;
  }

  return <HijackContext.Provider value={{}}>{children}</HijackContext.Provider>;
};
