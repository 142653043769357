import { createMachine, sendParent, assign } from 'xstate';

import { execute } from '../context/captcha-provider';

const getInitialState = () => {
  return {
    endpoint: undefined,
    user: undefined,
    fetchData: undefined,
    captchaToken: undefined,
    authToken: undefined,
    requestHeaders: {},
    requestBody: '',
    requestMethod: 'POST',
  };
};

const defaultConfig = {
  main: {
    guards: {},
    actions: {},
    services: {},
  },
};

export const createFetchMachine = (config = defaultConfig) => {
  /** @xstate-layout N4IgpgJg5mDOIC5QDMwBcDGALAdASwgBswBiAMQFEAVAYQAkBtABgF1FQAHAe1jzTy4A7diAAeiABwAWAOw4AzBIBMMpQFYAnOo1qZGgDQgAnpIBsOJjImm1pu1NMaZARiUBfN4dSZc37HkEoHGwwDABrAKgaIWQ8KBIAJQoABQB5BKoAfRpUgDkyAEkAcUyKBIT05jYkEG5efiERcQRXNRxTZxcJGTVDEwQAWmdNHDUpVqU7Jgl5B3kPL3RsHD8sSOCsUIjA6MFY+KS0jOy8wpLUgGkqkTq+AWEa5ucNJjaO5TVtGSlZPsQhyyjNTyJRMVzDDTyPQaBYgVYrJZrQIInyRGgAQw4PnRVC4YTAgkSKXSWRoAEFkrQ6GTMlRLhRcpkkjQKAUAGoUAAi1xqtwaD1AT2kbQ0Nm6k3kwKkkL+LXkznaEjUnQ6zikwMhplh8NW611O0x2Nx+MJhxJ2QpVJpdIuDNK5UqrBuPDujUeiGV8hwzyYGhm8iY6l9vWM-2cEgsEg0Wgkzz0PyYr21iJR-mR+qgZIArmgsMaCUSjlkyQBVKh0Wn0xnM1kc7lO3ku-lNRDhlwKGQg74udTqkP9KSJ9pMaV6HrvEHJnyppFBDPZ3P503E46l8uV22MsoVBI8zhN+4thBqMY4JRSeRQ2MvQfKWUqOSXn7WNRg+WSrWeOEpjM4ABGWZ4IQECRAkYAAI5ZnAaAkHutQHm6gqtmMcgjiel56N0mhSLKpiAkwih2EoULOM4sxTssv4Zpy6JoOihbmgAymUHIJJknJklQNI1uyXJwXyh7uggehKDgnZaK+0yio4BihseKhid0wymAGnbShRviInqWmBDRdEMcczEJKx7GcTS26OtU+71IJSEIN0cgqEqswSK5tjEbKrmOWosbXp2SquBpM7aaium0fRZrHLk1AAOrpBc9o7vxCECmIYY2N6pjEQReEvDIpjqrKMhgt60quNGnxKM4dgeF+ghcBAcAiPCBDEDgYAAE4dVwHWQM6NmIWlCCQt6pGxgVTBZf2iDnlIYmXvK1jyFoWiSkFrVgDg9UUF1PX9a6qXNOeTAWIoYK2DMGhSNIsqzBoOAaOCZFPp06rrUQYD7c2QnqqN4bVYOU2ysMbSdhh+VQhe0oSEFv4hOEaIxHEX22UNZGvgo11VbNJ6+rKQwvECsxWOefouDVX46jpc7UxiWLYDieIEijg1PCC91qlGsivDM55KMDChkZ042DtMBWdrD1PBYEC55kzqUCazrazCdxU9PINjRkokw4XJ1URs8r5+h0dhZTClM-lLAFASBgRgZB0Es4dyuDhYfrRm+2vqvIt0qQo2UyFYJPEZLoU02Henok7R5kSNPpKMooLWAREi4SN13WJM1Wgsq5uLNOGbR0JrixqVXPFT5IJSPzckDCo93odK2tZZM2saUXdl12RmXZRrvqWAV02DBrEaN65gags8Ui1W4QA */
  return createMachine(
    {
      context: getInitialState(),
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: 'fetch',
      initial: 'idle',
      states: {
        idle: {
          initial: 'noError',
          states: {
            errored: {},
            noError: {
              entry: 'clearContext',
            },
          },
          on: {
            FETCH: {
              target: 'fetching',
              actions: ['assignFetchContext'],
            },
          },
        },
        fetching: {
          initial: 'checkingConfig',
          states: {
            checkingConfig: {
              invoke: {
                src: 'checkConfig',
              },
              on: {
                REPORT_CONFIG_OK: {
                  target: 'fetchingCaptchaToken',
                },
                REPORT_CONFIG_ERROR: {
                  target: '#fetch.idle.errored',
                  actions: ['logErrorToConsole', 'sendErrorToParent'],
                },
              },
            },
            fetchingCaptchaToken: {
              invoke: {
                src: 'fetchCaptchaToken',
              },
              on: {
                REPORT_CAPTCHA_TOKEN_RECEIVED: {
                  target: 'fetchingAuthToken',
                  actions: 'assignCaptchaTokenToContext',
                },
                REPORT_CAPTCHA_TOKEN_ERROR: {
                  target: '#fetch.idle.errored',
                  actions: [`logErrorToConsole`, 'sendErrorToParent'],
                },
              },
            },
            fetchingAuthToken: {
              invoke: {
                src: 'fetchAuthToken',
              },
              on: {
                REPORT_AUTH_TOKEN_RECEIVED: {
                  target: 'buildingRequest',
                  actions: 'assignAuthTokenToContext',
                },
                REPORT_AUTH_TOKEN_ERROR: {
                  target: '#fetch.idle.errored',
                  actions: [`logErrorToConsole`, 'sendErrorToParent'],
                },
              },
            },
            buildingRequest: {
              entry: [
                'assignRequestMethodToContext',
                'assignRequestHeadersToContext',
                'assignRequestBodyToContext',
              ],
              always: {
                target: 'fetchingData',
              },
            },
            fetchingData: {
              invoke: {
                src: 'fetch',
              },
              on: {
                REPORT_SERVER_DATA_RECEIVED: {
                  target: '#fetch.idle',
                  actions: ['sendServerDataToParent'],
                },
                REPORT_SERVER_DATA_ERROR: {
                  target: '#fetch.idle.errored',
                  actions: [`logErrorToConsole`, 'sendErrorToParent'],
                },
                REPORT_NETWORK_ERROR: {
                  target: '#fetch.idle.errored',
                  actions: [`logErrorToConsole`, 'sendErrorToParent'],
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        logErrorToConsole: (context, event) => {
          if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
            console.error(`[FetchMachine.Context]`, context);
            console.error(`[FetchMachine.Event]`, event.error);
          }
        },
        clearContext: assign({
          ...getInitialState(),
        }),
        assignFetchContext: assign({
          endpoint: (_context, event) => {
            return event.endpoint;
          },
          user: (_context, event) => {
            return event.user;
          },
          fetchData: (_context, event) => {
            return event.fetchData;
          },
        }),
        assignCaptchaTokenToContext: assign({
          captchaToken: (_context, event) => {
            return event.token;
          },
        }),
        assignAuthTokenToContext: assign({
          authToken: (_context, event) => {
            return event.token;
          },
        }),
        sendServerDataToParent: sendParent((_context, event) => {
          return {
            type: 'FETCH_COMPLETE',
            serverData: event.serverData,
          };
        }),
        sendErrorToParent: sendParent((_context, event) => {
          return {
            type: 'FETCH_ERROR',
            serverData: event.error,
          };
        }),
        assignRequestMethodToContext: assign({
          requestMethod: () => {
            return 'POST';
          },
        }),
        assignRequestBodyToContext: assign({
          requestBody: () => {
            const body = {
              k: {}, // Required
              s: {}, // Optional
              o: {}, // Tracking
            };

            return JSON.stringify(body);
          },
        }),
        assignRequestHeadersToContext: assign({
          requestHeaders: (context, _event) => {
            const headers = {};
            const { authToken } = context;

            if (authToken) {
              headers.authorization = `Bearer ${authToken}`;
            }

            return headers;
          },
        }),
        ...config?.main?.actions,
      },
      services: {
        checkConfig: (context, _event) => (send) => {
          const { endpoint, fetchData } = context;

          const fetchConfig = [endpoint, fetchData];

          const okay = fetchConfig.every(Boolean);

          if (okay) {
            send({
              type: 'REPORT_CONFIG_OK',
            });
          } else {
            send({
              type: 'REPORT_CONFIG_ERROR',
              error: new Error(
                `There was a problem with your fetch config: ${JSON.stringify(
                  fetchConfig,
                )}`,
              ),
            });
          }
        },
        fetchCaptchaToken: () => (send) => {
          execute(process.env.GATSBY_RECAP_PUB_KEY, 'lead')
            .then((token) => {
              send({
                type: 'REPORT_CAPTCHA_TOKEN_RECEIVED',
                token,
              });
            })
            .catch((error) => {
              send({ type: 'REPORT_CAPTCHA_TOKEN_ERROR', error });
            });
        },
        fetchAuthToken: (context, _event) => (send) => {
          if (context.user) {
            context.user
              .getIdToken()
              .then((token) => {
                send({ type: 'REPORT_AUTH_TOKEN_RECEIVED', token });
              })
              .catch((error) => {
                send({ type: 'REPORT_AUTH_TOKEN_ERROR', error });
              });
          } else {
            send({ type: 'REPORT_AUTH_TOKEN_RECEIVED', token: '' });
          }
        },
        fetch: (context, _event) => (send) => {
          const { endpoint, requestMethod, requestBody, requestHeaders } =
            context;

          const fetchOpts = {
            method: requestMethod,
            headers: requestHeaders,
            body: requestBody,
          };

          fetch(endpoint, fetchOpts)
            .then((response) => {
              return response.json();
            })
            .then((serverData) => {
              if (serverData.code) {
                send({ type: 'REPORT_SERVER_DATA_ERROR', error: serverData });
              } else {
                send({ type: 'REPORT_SERVER_DATA_RECEIVED', serverData });
              }
            })
            .catch((error) => {
              send({ type: 'REPORT_NETWORK_ERROR', error });
            });
        },
        ...config?.main?.services,
      },
      guards: {
        ...config?.main?.guards,
      },
    },
  );
};
