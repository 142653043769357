import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#e4ff58",
    },
    secondary: {
      main: "#0066cc",
    },
  },
  typography: { fontFamily: ["Open Sans"].join(",") },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.8)"
        }
      }
    }
  }
})

export default theme
