import React, { createContext, useEffect } from 'react';

import { useInterpret } from '@xstate/react';

import supervisorMachine from '../machines/supervisor-machine';

export const SupervisorContext = createContext({});

export const SupervisorProvider = ({ children }) => {
  const service = useInterpret(supervisorMachine, { devTools: true });

  useEffect(() => {
    service.send('PREFETCH', {
      modules: [{ serviceId: 'firebase' }],
    });
  }, []);

  return (
    <SupervisorContext.Provider value={{ service }}>
      {children}
    </SupervisorContext.Provider>
  );
};
