import React, { createContext, useContext } from 'react';

export const FunnelRoutingContext = createContext([]);

export const FunnelRoutingProvider = ({ children, data = {} }) => {
  const { kalansoPage = {} } = data;
  const { routes = [] } = kalansoPage;

  return (
    <FunnelRoutingContext.Provider value={routes}>
      {children}
    </FunnelRoutingContext.Provider>
  );
};

export const useFunnelRouting = () => {
  const context = useContext(FunnelRoutingContext);

  if (context === undefined) {
    throw new Error(
      `useFunnelRouting may only be used within a FunnelRoutingContext`,
    );
  }

  const get = (name, throwExceptionOnMissingRoute = false) => {
    const route = context.find((r) => r.name === name);

    if (!route) {
      const errorMessage = `Missing Route: ${name}`;
      console.error(errorMessage);

      if (throwExceptionOnMissingRoute) {
        throw new Error(errorMessage);
      }
    }

    return route;
  };

  return {
    get,
  };
};
