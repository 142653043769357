import React, { createContext, useContext } from 'react';
import { useSelector } from '@xstate/react';

import { SupervisorContext } from './supervisor-provider';

// Selectors
const selectFirestoreService = (state) => state.children['firestore'];

export const createActionFirestore = (event, ...args) => ({
  type: 'SERVICE.CALL',
  serviceId: 'firestore',
  event,
  arguments: [...args],
});

export const FirebaseFirestoreContext = createContext();

export const FirebaseFirestoreProvider = (props) => {
  const supervisor = useContext(SupervisorContext);
  const service = useSelector(supervisor.service, selectFirestoreService);

  return (
    <FirebaseFirestoreContext.Provider
      value={{
        supervisor: supervisor.service,
        service,
      }}
    >
      {props.children}
    </FirebaseFirestoreContext.Provider>
  );
};

export const useFirestore = () => {
  const actions = {};

  return { actions };
};
