import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import { SupervisorContext } from './supervisor-provider';

import canUseDOM from '../components/shared/can-use-dom';

import { createActionAnalytics } from './firebase-analytics-provider';

import { decodeBase64 } from '../components/shared/base-64';

const CUSTOM_TOKEN_KEY = 'ct';
const CUSTOM_SIGNOUT_TOKEN_KEY = 'signout';

// Selectors
const selectAuthService = (state) => state.children['auth'];
const selectAuthState = (state) => state;
const selectAuthUser = (state) => state.context.user;
const selectAuthError = (state) => state.context.error;
const selectAuthBundle = (state) => state.context.installations['auth']?.bundle;
const selectAuthImpl = (state) => state.context.installations['auth']?.instance;

export const createActionAuth = (event, ...args) => ({
  type: 'SERVICE.CALL',
  serviceId: 'auth',
  event,
  arguments: [...args],
});

export const isCustomTokenURL = () => {
  if (canUseDOM()) {
    const params = new URLSearchParams(window.location.search);
    return params.has(CUSTOM_TOKEN_KEY);
  }
  return false;
};

export const getCustomToken = () => {
  if (canUseDOM() && isCustomTokenURL()) {
    const params = new URLSearchParams(window.location.search);
    const token64 = params.get(CUSTOM_TOKEN_KEY);
    const token = decodeBase64(token64);

    return token;
  }
};

export const isCustomSignOutURL = () => {
  if (canUseDOM()) {
    const params = new URLSearchParams(window.location.search);
    const customToken = params.get(CUSTOM_TOKEN_KEY);
    return customToken === CUSTOM_SIGNOUT_TOKEN_KEY;
  }
  return false;
};

export const FirebaseAuthContext = createContext();

export const FirebaseAuthProvider = (props) => {
  const supervisor = useContext(SupervisorContext);
  const authService = useSelector(supervisor.service, selectAuthService);
  const authState = useSelector(authService, selectAuthState);
  const bundle = useSelector(supervisor.service, selectAuthBundle);
  const impl = useSelector(supervisor.service, selectAuthImpl);
  const user = useSelector(authService, selectAuthUser);
  const error = useSelector(authService, selectAuthError);

  const [lock, setLock] = useState(isCustomTokenURL);
  const [initialHijack] = useState(lock);

  const isSdkReady = Boolean(bundle) && Boolean(impl);
  const synchronized = isSdkReady && user !== undefined;

  useEffect(() => {
    let unsubscribe;

    if (isSdkReady) {
      unsubscribe = bundle.onAuthStateChanged(impl, (currentUser) => {
        let shouldFreeLock = false;

        // If we using the custom token feature to authenticate
        // we block rendering of all child components until the
        // authenticated attempt resolves with either a signed in or
        // signed out user.
        if (isCustomSignOutURL()) {
          if (!currentUser) {
            shouldFreeLock = true;
          }
        } else if (isCustomTokenURL()) {
          shouldFreeLock = true;
        } else {
          shouldFreeLock = false;
        }

        if (currentUser && !isCustomSignOutURL()) {
          supervisor.service.send(
            createActionAuth('SIGN_IN', { user: currentUser }),
          );
        } else {
          supervisor.service.send(createActionAuth('SIGN_OUT'));
        }

        // Setup user tracking with GA4
        supervisor.service.send(
          createActionAnalytics('SET_USER_ID', {
            user: currentUser,
          }),
        );

        // Setup user tracking with Clarity
        if ('clarity' in window) {
          window.clarity('identify', currentUser ? currentUser.uid : null);
        }

        if (shouldFreeLock) {
          setLock(false);
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [isSdkReady, bundle, impl, supervisor]);

  useEffect(() => {
    if (isCustomTokenURL() && !isCustomSignOutURL()) {
      const token = getCustomToken();
      supervisor.service.send(
        createActionAuth('SIGN_IN_WITH_CUSTOM_TOKEN', { token }),
      );
    }
  }, [supervisor]);

  useEffect(() => {
    if (isCustomSignOutURL()) {
      supervisor.service.send(createActionAuth('SIGN_OUT'));
    }
  }, [supervisor]);

  // If we're signing in via custom token, we prevent children from rendering
  // until the authentication attempt completes.
  const hijack = lock || ['signingInWithCustomToken'].some(authState.matches);

  return (
    <FirebaseAuthContext.Provider
      value={{
        supervisor: supervisor.service,
        service: authService,
        synchronized,
        hijack: { initial: initialHijack, current: hijack },
        user,
        error,
      }}
    >
      {props.children}
    </FirebaseAuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(FirebaseAuthContext);

  if (context === undefined) {
    throw new Error(`useAuth may only be used within a FirebaseAuthContext`);
  }

  const sendEmailSignInLink = (...args) => {
    context.supervisor.send(
      createActionAuth('SEND_SIGN_IN_LINK_TO_EMAIL', ...args),
    );
  };

  const signInWithEmailLink = (...args) => {
    context.supervisor.send(
      createActionAuth('SIGN_IN_WITH_EMAIL_LINK', ...args),
    );
  };

  const signInAnonymously = (...args) => {
    context.supervisor.send(createActionAuth('SIGN_IN_ANONYMOUSLY', ...args));
  };

  const signInWithCustomToken = () => {
    context.supervisor.send(
      createActionAuth('SIGN_IN_WITH_CUSTOM_TOKEN', {
        token: getCustomToken(),
      }),
    );
  };

  const convertToPermanentAccount = (...args) => {
    context.supervisor.send(
      createActionAuth('CONVERT_TO_PERMANENT_ACCOUNT', ...args),
    );
  };

  const signOut = (...args) => {
    context.supervisor.send(context.createActionAuth('SIGN_OUT', ...args));
  };

  const getIdToken = () => {
    context.supervisor.send(context.createActionAuth('GET_ID_TOKEN'));
  };

  const prefetch = () => {
    context.supervisor.send('PREFETCH', {
      modules: [{ serviceId: 'auth' }],
    });
  };

  const actions = {
    signOut,
    sendEmailSignInLink,
    signInWithEmailLink,
    signInAnonymously,
    signInWithCustomToken,
    convertToPermanentAccount,
    getIdToken,
    prefetch,
  };

  return {
    synchronized: context.synchronized,
    hijack: context.hijack,
    user: context.user,
    error: context.error,
    service: context.service,
    actions,
  };
};
