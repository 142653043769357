const getConditionalProps = (props = []) => {
  return props
    .filter((prop) => {
      return typeof prop[2] === 'function'
        ? Boolean(prop[2]())
        : Boolean(prop[2]);
    })
    .map((prop) => ({ [prop[0]]: prop[1] }))
    .reduce((acc, prop) => ({ ...acc, ...prop }), {});
};

export { getConditionalProps };
