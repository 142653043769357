import { createMachine } from 'xstate';

const isProd = process.env.GATSBY_ACTIVE_ENV === 'production';
const okToConsoleLog = !isProd;

const log = (...args) => {
  if (okToConsoleLog) {
    console.log(
      ...[`[${process.env.GATSBY_ACTIVE_ENV}.${window.NF_AB}]`, ...args],
    );
  }
};

const loggerMachine = createMachine({
  preserveActionOrder: true,
  predictableActionArguments: true,
  id: 'logger',
  initial: 'listening',
  states: {
    listening: {
      on: {
        LOG: {
          actions: (_context, event) => log(event),
        },
      },
    },
  },
});

export default loggerMachine;
