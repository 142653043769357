import React, { createContext, useContext, useEffect } from 'react';

import { useLearningHouse } from './learning-house-provider';
import { useAuth } from './firebase-auth-provider';

export const ClarityContext = createContext();

const c = 'clarity';

// Bootstrap clarity
// See: https://github.com/microsoft/clarity/blob/master/packages/clarity-js/src/global.ts
const bootstrap = () => {
  if (window[c] === undefined) {
    window[c] = function () {
      (window[c].q = window[c].q || []).push(arguments);
    };
  }
};

export const ClarityProvider = ({ children, data }) => {
  const { visitor } = useLearningHouse();
  const { user } = useAuth();

  const { kalansoPage = {} } = data || {};
  const { analytics = {} } = kalansoPage;

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    const { pageId, packId, funnelId, branch, environment } = analytics;
    if ([pageId, packId, funnelId, branch, environment].every(Boolean)) {
      window[c]('set', 'Environment', environment);
      window[c]('set', 'Branch', branch);
      window[c]('set', 'PackId', packId);
      window[c]('set', 'FunnelId', funnelId);
      window[c]('set', 'PageId', pageId);
    }
  }, [analytics]);

  useEffect(() => {
    const { pageId } = analytics;
    const session = [user ? user.uid : null, visitor.identifier, pageId];
    if (session.some(Boolean)) {
      window[c]('identify', ...session);
    }
  }, [user, visitor, analytics]);

  return (
    <>
      <ClarityContext.Provider value={{}}>{children}</ClarityContext.Provider>
    </>
  );
};

export const useClarity = () => {
  const context = useContext(ClarityContext);

  if (context === undefined) {
    throw new Error(`useClarity must be used within a ClarityContext`);
  }

  const set = (...args) => {
    window[c](...args);
  };

  const api = {
    set,
  };

  return api;
};
