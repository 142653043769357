import { toEnum } from '../components/shared/to-enum';

export const SUPPORTED_EVENTS = {
  // Auth
  LOG_IN: 'login', // GA4 Standard
  LOG_OUT: 'logout',
  ANONYMOUS_USER_UPGRADE: 'anonymous_user_upgrade',
  MAGIC_LINK_SEND: 'magic_link_send',

  // Growthbook / Split Testing
  EXPERIMENT_VIEW: 'experiment_view',

  // Kalanso Funnels
  FUNNEL_PAGE_VIEW: 'funnel_page_view',
  FUNNEL_CLICK: 'funnel_click',
  FUNNEL_IMPRESSION: 'funnel_impression',
  FUNNEL_FORM_SUBMIT: 'funnel_form_submit',
  FUNNEL_FORM_SUBMIT_SUCCESS: 'funnel_form_submit_success',
  FUNNEL_FORM_SUBMIT_ERROR: 'funnel_form_submit_error',
  FUNNEL_MEDIA_VIEW: 'funnel_media_view',
  FUNNEL_ENTRY: 'funnel_entry',

  // GA4 Standard Events
  VIDEO_START: 'video_start',
  VIDEO_PROGRESS: 'video_progress',
  VIDEO_COMPLETE: 'video_complete',
  PURCHASE: 'purchase',
  SIGN_UP: 'sign_up',
  ADD_TO_CART: 'add_to_cart',
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  BEGIN_CHECKOUT: 'begin_checkout',
  GENERATE_LEAD: 'generate_lead',
  REFUND: 'refund',
  REMOVE_FROM_CART: 'remove_from_cart',
  SELECT_ITEM: 'select_item',
  SELECT_PROMOTION: 'select_promotion',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_PROMOTION: 'view_item_list',
};

export const EVENT_TYPES = {
  CLICK: 'click',
  PAGE_VIEW: 'page_view',
  VIDEO_ENGAGEMENT: 'video_engagement',
  FORM_SUBMIT: 'form_submit',
  EXPERIMENT: 'experiment',
  UNSPECIFIED: 'unspecified',
};

export const EVENTS = toEnum(SUPPORTED_EVENTS);
