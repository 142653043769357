import { createMachine } from 'xstate';
import { SUPPORTED_EVENTS } from '../constants/analtyics-events';

const analyticsMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMB2yA2BPALgSwGNYA6DPWHMVPVKAYgBkB5AcQH0BRANQ4DkAVANoAGALqJQABwD2sPPmmoJIAB6IAtAEYA7AE5iANgAcJgMzDtB4wBYANCCyJTR4gCZn2o64Cs205u9hE28AXxD7NExcQhIyCioaegBlDn42AFUUgCU2AEkAERFxJBAZOQUlErUEA2Jvfx1vTSNTP01zbXtHBGtTa2JrXWMDYWtvL2Fdb2swiPRsfCJSaSgoRLoi5TL5PEVlar7iYWFnc2tz438uxADakfGdV21PXxnwkEiFmOJYMBx8WjpX4AJ1yEA2Yi2sh2eyqGk01lqvVc7VcBjG2mm1x6aOImgMpl8riCRhsulMYXeqGkEDgyk+0SWcUo1FoUPKu0qoGqWmcA1erjRmmEgusriM2PFwjqOiMRJMY1Gsw+80ZsRWazZJW2FX2iFcbjOfmECPF7Va2PUuhcpgJKNJU0GT1cyoZixIv3+iSBYFBEHZMK5qg0rWIugs3l0mkFz0G2jsDkQBlc+iM2nFeiC1iM5O0rtV7oDurhCHUMcMJhaFisRgT3S0aeIttMKKaY2MTQplKAA */
  createMachine(
    {
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: 'analytics',
      initial: 'listening',
      states: {
        listening: {
          on: {
            LOG_EVENT: {
              target: 'logging',
            },
            SET_USER_ID: {
              target: 'settingUserId',
            },
          },
        },
        logging: {
          invoke: {
            src: 'logEvent',
          },
          always: {
            target: 'listening',
          },
        },
        settingUserId: {
          invoke: {
            src: 'setUserId',
          },
          always: {
            target: 'listening',
          },
        },
      },
    },
    {
      services: {
        logEvent: (_context, event) => {
          const { logEvent } = event.payload.bundle;

          const [eventName, eventParams, eventOptions] =
            event.payload.arguments;

          if (!SUPPORTED_EVENTS[eventName]) {
            console.warn(
              `Analytics Event No Support`,
              JSON.stringify(event.payload.arguments),
            );
          } else {
            logEvent(
              event.payload.instance,
              SUPPORTED_EVENTS[eventName],
              eventParams,
              eventOptions,
            );
          }

          return Promise.resolve();
        },
        setUserId: (_context, event) => {
          const { setUserId } = event.payload.bundle;
          const [{ user }] = event.payload.arguments;

          setUserId(event.payload.instance, user ? user.uid : null);

          return Promise.resolve();
        },
      },
    },
  );

export default analyticsMachine;
