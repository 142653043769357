import React, { createContext, useContext, useEffect } from 'react';

import { useAuth } from './firebase-auth-provider';

import { SupervisorContext } from './supervisor-provider';

import { useSelector } from '@xstate/react';

export const LeadMachineContext = createContext();

export const createActionLeadMachine = (event, ...args) => ({
  type: 'SERVICE.CALL',
  serviceId: 'funnel/leads',
  event,
  arguments: [...args],
});

const selectLeadsService = (state) => state.children['funnel/leads'];

export const LeadMachineProvider = ({ children }) => {
  const supervisor = useContext(SupervisorContext);
  const service = useSelector(supervisor.service, selectLeadsService);

  return (
    <LeadMachineContext.Provider value={{ service }}>
      {children}
    </LeadMachineContext.Provider>
  );
};

/**
 * Use this hook inside of a Lead Form.
 */
export const useLeadMachine = () => {
  const context = useContext(LeadMachineContext);
  const auth = useAuth();

  if (context === undefined) {
    throw new Error(
      `useLeadMachine may only be used with a LeadMachineContext`,
    );
  }

  useEffect(() => {
    if (!auth.synchronized) {
      // Kick off a PREFETCH in case we still
      // need to download and install the auth library
      auth.actions.prefetch();
    }
  }, []);

  return context.service;
};
