// WARNING: This code is copied from 'packages/kalanso-rules/src/rules.js'
var _regexCache = {};

// The top-level condition evaluation function
function evalCondition(obj, condition) {
  // Recursive condition
  if ('$or' in condition) {
    return evalOr(obj, condition['$or']);
  }
  if ('$nor' in condition) {
    return !evalOr(obj, condition['$nor']);
  }
  if ('$and' in condition) {
    return evalAnd(obj, condition['$and']);
  }
  if ('$not' in condition) {
    return !evalCondition(obj, condition['$not']);
  }
  // Condition is an object, keys are object paths, values are the condition for that path
  for (var _i = 0, _a = Object.entries(condition); _i < _a.length; _i++) {
    var _b = _a[_i],
      k = _b[0],
      v = _b[1];
    if (!evalConditionValue(v, getPath(obj, k))) return false;
  }
  return true;
}

// Return value at dot-separated path of an object
function getPath(obj, path) {
  // If path is using curlies, return if dot-separated key matches top-level key
  if (path.startsWith('{{') && path.endsWith('}}')) {
    if (obj && typeof obj === 'object' && path in obj) {
      return obj[path];
    }
  }

  var parts = path.split('.');
  var current = obj;
  for (var i = 0; i < parts.length; i++) {
    if (current && typeof current === 'object' && parts[i] in current) {
      current = current[parts[i]];
    } else {
      return null;
    }
  }
  return current;
}
// Transform a regex string into a real RegExp object
function getRegex(regex) {
  if (!_regexCache[regex]) {
    _regexCache[regex] = new RegExp(regex.replace(/([^\\])\//g, '$1\\/'));
  }
  return _regexCache[regex];
}
// Evaluate a single value against a condition
function evalConditionValue(condition, value) {
  // Simple equality comparisons
  if (typeof condition === 'string') {
    return value + '' === condition;
  }
  if (typeof condition === 'number') {
    return value * 1 === condition;
  }
  if (typeof condition === 'boolean') {
    return !!value === condition;
  }
  if (Array.isArray(condition) || !isOperatorObject(condition)) {
    return JSON.stringify(value) === JSON.stringify(condition);
  }
  // This is a special operator condition and we should evaluate each one separately
  for (const op in condition) {
    if (!evalOperatorCondition(op, value, condition[op])) {
      return false;
    }
  }
  return true;
}
// If the object has only keys that start with '$'
function isOperatorObject(obj) {
  var keys = Object.keys(obj);
  return (
    keys.length > 0 &&
    keys.filter(function (k) {
      return k[0] === '$';
    }).length === keys.length
  );
}
// Return the data type of a value
function getType(v) {
  if (v === null) return 'null';
  if (Array.isArray(v)) return 'array';
  var t = typeof v;
  if (['string', 'number', 'boolean', 'object', 'undefined'].includes(t)) {
    return t;
  }
  return 'unknown';
}
// At least one element of actual must match the expected condition/value
function elemMatch(actual, expected) {
  if (!Array.isArray(actual)) return false;
  var check = isOperatorObject(expected)
    ? function (v) {
        return evalConditionValue(expected, v);
      }
    : function (v) {
        return evalCondition(v, expected);
      };
  for (var i = 0; i < actual.length; i++) {
    if (actual[i] && check(actual[i])) {
      return true;
    }
  }
  return false;
}
// Evaluate a single operator condition
function evalOperatorCondition(operator, actual, expected) {
  switch (operator) {
    case '$eq':
      return actual === expected;
    case '$ne':
      return actual !== expected;
    case '$lt':
      return actual < expected;
    case '$lte':
      return actual <= expected;
    case '$gt':
      return actual > expected;
    case '$gte':
      return actual >= expected;
    case '$exists':
      return expected ? actual !== null : actual === null;
    case '$in':
      return expected.includes(actual);
    case '$nin':
      return !expected.includes(actual);
    case '$not':
      return !evalConditionValue(expected, actual);
    case '$size':
      if (!Array.isArray(actual)) return false;
      return evalConditionValue(expected, actual.length);
    case '$elemMatch':
      return elemMatch(actual, expected);
    case '$all':
      if (!Array.isArray(actual)) return false;
      for (var i = 0; i < expected.length; i++) {
        var passed = false;
        for (var j = 0; j < actual.length; j++) {
          if (evalConditionValue(expected[i], actual[j])) {
            passed = true;
            break;
          }
        }
        if (!passed) return false;
      }
      return true;
    case '$regex':
      try {
        return getRegex(expected).test(actual);
      } catch (e) {
        return false;
      }
    case '$type':
      return getType(actual) === expected;
    default:
      console.error('Unknown operator: ' + operator);
      return false;
  }
}
// Recursive $or rule
function evalOr(obj, conditions) {
  if (!conditions.length) return true;
  for (var i = 0; i < conditions.length; i++) {
    if (evalCondition(obj, conditions[i])) {
      return true;
    }
  }
  return false;
}
// Recursive $and rule
function evalAnd(obj, conditions) {
  for (var i = 0; i < conditions.length; i++) {
    if (!evalCondition(obj, conditions[i])) {
      return false;
    }
  }
  return true;
}

function evaluate(testedObj, rules) {
  return evalCondition(testedObj, rules);
}

export { evaluate }
