import React from 'react';

import PropTypes from 'prop-types';

import { Link as MuiLink } from 'gatsby-theme-material-ui';

import { getConditionalProps } from './get-conditional-props';

import { useFunnelAnalytics } from '../../context/funnel-analytics-provider';
import { useLearningHouse } from '../../context/learning-house-provider';

import { EVENTS } from '../../constants/analtyics-events';

const Link = ({ to, onClick, linkProps, children }) => {
  const { htmlData = {}, ...muiLinkProps } = linkProps;

  const conditionalProps = getConditionalProps([
    ['data-gtm-click', 'true', true],
    ...Object.entries(htmlData).map(([key, value]) => [key, value, true]),
  ]);

  const analytics = useFunnelAnalytics();
  const learningHouse = useLearningHouse();

  const handleClick = (e) => {
    analytics.actions.logEvent(EVENTS.FUNNEL_CLICK, {
      target: e.target,
      visitor: learningHouse.visitor,
    });

    onClick && onClick();
  };

  return (
    <MuiLink
      to={to}
      {...muiLinkProps}
      {...conditionalProps}
      onClick={handleClick}
    >
      {children}
    </MuiLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  linkProps: PropTypes.object,
};

Link.defaultProps = {
  linkProps: {},
};

export default Link;
