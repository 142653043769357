import React from 'react';

// Root Providers
// ===============
import { SupervisorProvider } from './src/context/supervisor-provider';
import { FirebaseAnalyticsProvider } from './src/context/firebase-analytics-provider';
import { FirebaseAuthProvider } from './src/context/firebase-auth-provider';
import { FirebaseFirestoreProvider } from './src/context/firebase-firestore-provider';
import { AirbrakeProvider } from './src/context/airbrake-provider';
import { CaptchaProvider } from './src/context/captcha-provider';

// Page Providers
// ===============
// import Seo from './src/components/shared/seo';
import { HijackProvider } from './src/context/hijack-provider';
import { FunnelAnalyticsProvider } from './src/context/funnel-analytics-provider';
import { LearningHouseProvider } from './src/context/learning-house-provider';
import { FunnelPageViewProvider } from './src/context/funnel-page-view-provider';
import { FunnelRoutingProvider } from './src/context/funnel-routing-provider';
import { FunnelContactProvider } from './src/context/funnel-contact-provider';
import { FulcrumProvider } from './src/context/fulcrum-provider';
import { LeadMachineProvider } from './src/context/lead-machine-provider';
import { HeadlessProvider } from './src/context/headless-provider';
import { ClarityProvider } from './src/context/clarity-provider';

import {
  MetaPixel,
  MetaPixelNoScript,
} from './src/components/scripts/meta-pixel';

import { ClarityPixel } from './src/components/scripts/clarity';

import ReCAPTCHA from './src/components/scripts/recaptcha';

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <HijackProvider {...props}>
        <FunnelAnalyticsProvider {...props}>
          <LearningHouseProvider {...props}>
            <HeadlessProvider {...props}>
              <FunnelPageViewProvider {...props}>
                <FunnelRoutingProvider {...props}>
                  <FunnelContactProvider {...props}>
                    <ClarityProvider {...props}>{element}</ClarityProvider>
                  </FunnelContactProvider>
                </FunnelRoutingProvider>
              </FunnelPageViewProvider>
            </HeadlessProvider>
          </LearningHouseProvider>
        </FunnelAnalyticsProvider>
      </HijackProvider>
    </>
  );
};

// Every page, authenticated or not, will have access
// to these providers.
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <SupervisorProvider>
        <AirbrakeProvider>
          <CaptchaProvider>
            <FirebaseAnalyticsProvider>
              <FirebaseAuthProvider>
                <FirebaseFirestoreProvider>
                  <FulcrumProvider>
                    <LeadMachineProvider>{element}</LeadMachineProvider>
                  </FulcrumProvider>
                </FirebaseFirestoreProvider>
              </FirebaseAuthProvider>
            </FirebaseAnalyticsProvider>
          </CaptchaProvider>
        </AirbrakeProvider>
      </SupervisorProvider>

      {/* Tracking Codes */}
      <MetaPixel />
      <MetaPixelNoScript />
      <ClarityPixel />
      <ReCAPTCHA />
    </>
  );
};
