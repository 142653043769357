import React, { useContext, useMemo, useEffect, useCallback } from 'react';

import Cookie from 'cookie-universal';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { SupervisorContext } from './supervisor-provider';
import {
  FunnelAnalyticsContext,
  constructEvent,
} from './funnel-analytics-provider';

import { createActionAnalytics } from './firebase-analytics-provider';
import { EVENTS } from '../constants/analtyics-events';

export const LearningHouseContext = React.createContext(null);

// import { useGrowthBook } from "@growthbook/growthbook-react";

// function MyComponent() {
//   const growthbook = useGrowthBook();
//   return (
//     <div>{growthbook.getFeatureValue("feat:rtubg:highlight", "fallback value")}</div>
//   )
// }

const getVisitor = (serverData = {}) => {
  const cookies = Cookie();

  // The identifier will either be passed through as serverData
  // or will be found in the cookie jar. The cookie is set through a
  // Netlify Edge Function.
  const identifier =
    (serverData && serverData[process.env.GATSBY_LEARNING_HOUSE_KEY]) ||
    cookies.get(process.env.GATSBY_LEARNING_HOUSE_COOKIE) ||
    undefined;

  const visitor = {
    key: process.env.GATSBY_LEARNING_HOUSE_KEY,
    identifier,
  };

  return visitor;
};

/**
 *
 * Example Inline Experiment
 * =========================
 *
 * const experiment = {
 *  key: "greetings",
 *  variations: ["Hello", "Goodbye!"],
 *  hashAttribute: process.env.GATSBY_LEARNING_HOUSE_KEY
 * }
 *
 * const experimentResult = useExperiment(experiment);
 *
 * Do stuff with `experimentResult`
 *
 * See: https://docs.growthbook.io/lib/build-your-own#experiment
 *
 */

export const LearningHouseProvider = ({ children, data, serverData }) => {
  const supervisor = useContext(SupervisorContext);
  const { service } = supervisor;
  const { kalansoPage = {} } = data || {};
  const { features = [] } = kalansoPage;

  const { dataLayer } = useContext(FunnelAnalyticsContext);

  const visitor = useMemo(() => {
    return getVisitor(serverData);
  }, [serverData]);

  const attributes = useMemo(() => {
    return {
      [visitor.key]: visitor.identifier,
    };
  }, [visitor]);

  const liveFeatures = useMemo(() => {
    return features.reduce((acc, feat) => {
      return {
        ...acc,
        [feat.key]: JSON.parse(feat.feature),
      };
    }, {});
  }, [features]);

  const logImpression = useCallback(
    (experiment, result) => {
      const [eventName, eventParams, eventOptions] = constructEvent(
        EVENTS.EXPERIMENT_VIEW,
        {
          experiment,
          result,
          dataLayer,
          visitor,
        },
      );

      service.send(
        createActionAnalytics(
          'LOG_EVENT',
          eventName,
          eventParams,
          eventOptions,
        ),
      );
    },
    [visitor, dataLayer, service],
  );

  const growthbook = useMemo(() => {
    return new GrowthBook({
      attributes,
      features: liveFeatures,
      trackingCallback: logImpression,
    });
  }, [attributes, liveFeatures, logImpression]);

  useEffect(() => {
    // Cleanup GrowthBook instance
    return () => {
      growthbook.destroy();
    };
  }, [growthbook]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <LearningHouseContext.Provider value={{ visitor }}>
        {children}
      </LearningHouseContext.Provider>
    </GrowthBookProvider>
  );
};

export const useLearningHouse = () => {
  const context = useContext(LearningHouseContext);

  return context;
};
