const canUseDOM = () => {
	try {
		return !!(
			typeof window !== 'undefined' &&
			window.document &&
			window.document.createElement
		);
	} catch (error) {
		return false;
	}
}

export default canUseDOM;
