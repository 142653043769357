import React from 'react';

import { Script } from 'gatsby';

const metaPixel = process.env.GATSBY_FB_PIXEL_ID;

export const MetaPixel = () => {
  if (!metaPixel) {
    return null;
  }

  return (
    <Script id="meta-pixel" strategy="idle">
      {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${metaPixel}');
fbq('track', 'PageView');
`.trim()}
    </Script>
  );
};

export const MetaPixelNoScript = () => {
  if (!metaPixel) {
    return null;
  }

  return (
    <noscript
      id="meta-pixel-noscript"
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${metaPixel}&ev=PageView&noscript=1"
          />`,
      }}
    />
  );
};
