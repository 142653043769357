import { createMachine, assign, send } from 'xstate';

import { evaluate } from '../components/shared/rules-engine';

import { createFetchMachine } from './fetch-machine';

import { requestIdleCallback } from '../components/shared/request-idle-callback-shim';

const splitter = /\s+|-+|_+|=+/gm;

// Helper for turning strings into a dot.separated string
const dots = (str) => str.split(splitter).filter(Boolean).join('.');

const createDotSeparatedKeys = (obj, opener = '', closer = '') => {
  const bank = {};

  Object.keys(obj).forEach((key) => {
    bank[`${opener}${dots(key)}${closer}`] = obj[key];
  });

  return bank;
};

// Wraps object keys in curlies
export const createBank = (obj) => {
  return createDotSeparatedKeys(obj, '{{', '}}');
};

function evaluateFulcrumEvent(context) {
  const { bank, trackable, workflows } = context;

  if (!trackable) {
    return false;
  }

  if (!Array.isArray(trackable.triggers)) {
    return false;
  }

  if (trackable.triggers.length === 0) {
    return false;
  }

  if (!bank || typeof bank !== 'object' || Object.keys(bank).length === 0) {
    throw new Error(`Invalid bank`);
  }

  if (
    !trackable.triggers.every((trigger) =>
      ['workflowId', 'triggerId', 'rules'].every((property) =>
        Boolean(trigger[property]),
      ),
    )
  ) {
    throw new Error(`Invalid triggers found`);
  }

  let found;

  for (const trigger of trackable.triggers) {
    if (workflows[trigger.wid]) {
      // We found the trigger, but we already fired its associated workflow.
      // Skip this one, and eval the other triggers if applicable
      continue;
    }

    // We've already found a trigger during this run so we can stop here.
    if (found) {
      continue;
    }

    const rules =
      typeof trigger.rules === 'string'
        ? JSON.parse(trigger.rules)
        : trigger.rules;

    if (evaluate(bank, rules)) {
      found = trigger;
    }
  }

  return found;
}

const formatClientAttributes = (bank) => {
  return Object.entries(bank)
    .filter(([key]) => key.match(/^{{client\./))
    .reduce((acc, [key, value]) => {
      const cleanedKey = key
        .replace('{{', '')
        .replace('}}', '')
        .replace('client.', '');
      return {
        ...acc,
        [cleanedKey]: value,
      };
    }, {});
};

const formatRequestBody = (context) => {
  const { captchaToken, fetchData } = context;

  const { bank, trackable, trigger } = fetchData;

  const pkid = trackable.packId || trackable.parent.packId;

  const body = {
    k: {
      rt: captchaToken,
      pkid, // How safe is this?
      wfid: trigger.workflowId,
      trid: trigger.triggerId,
      esurl: window.location.href,
    }, // Required
    s: {
      client: formatClientAttributes(bank),
    }, // Optional
    o: {}, // Tracking
  };

  return JSON.stringify(body);
};

const fulcrumFetchConfig = {
  main: {
    actions: {
      assignRequestBodyToContext: assign({
        requestBody: formatRequestBody,
      }),
    },
  },
};

const getInitialState = () => {
  return {
    user: undefined,
    workflows: {},
    trigger: undefined,
    bank: undefined,
    trackable: undefined,
  };
};

export const createFulcrumMachine = (name) => {
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlwgBswBiAGQHkBxAfQFEA1VgOQBUBtAAwBdRKAAOAe1i4ALrgn5RIAB6IAbAGYSAFgDs2gRoAchgEzaAjIbUAaEAE9E27QE4SugKynTLlx43aph7aRgC+oXZoWHiEpGAAbugUAK7ocvhQ1IIiSCCS0nIKSqoIXh4k-mrmXn4eRmq6do6lviQWHpoWGgJGFtpqLmERIFE4BMQkCUmp6Zl8FjniUrLyirkl-loCanUuFmoCXS6BTYgaum6m3Rp9ajseAi5q4ZEYY7GTiSlpBHOmi3lloU1qASlZTG1PNtTBYgmoLIMXKcEBodjofPoAkYXN4NMcXiM3jEJhQJFAoL9qAAxVg8ADCAAlmHT6ABZAAKtFprGySnyKyK60QF10JBuLl02Jhri6yJMFjFngMN328I8BNGxNIpPJlJp9KZrAASkb6Ebebl+cDimcQiRTFVYVcBqicciJW4LAjDiZdndTBqieNSLBkphMHBYFlhHygasbQgLm46v4cXscT0kQ5EF6DjodgdjppHYHosGSKHw5GsgtYwV40KEBZejoNKiegjdKZDAFkbmBPmOo9+qiYaX3hMwAAnKcSKfRgFWhugxAAWmbRntXiMBjqAWCx2RulRJDUzhMRg8ugEnmx4WG+AkEDgSk15fIVDrApBKjXGiCbTdK4xguI8XpZs0GhePa1wOvcPSeOOWqfNMPwZF+1qNuuYrdj4Rj6MePR6AI2jIh0EIHKYx64d4CLPMMb4fDqFLoZacaCiupS6Go9pXLCgyos2R4dGKHgeF0DztDCAYMUGHyVhGsDwGx9Ycb+iZtm0dQWEmxgdo02YIC4J46VBWJdlcuhIeW06zlOGHLupAjumJbTeq4N7eAW1kfFOkC4H5mCzA5algtsm5nnsVzbMEVRGMixjlNsbaBBcAgCF42g+cQIU-iUq7nFoXQkcZ2JgQiyIwuU0U3ME-6+FcWX3kAA */
  return createMachine(
    {
      context: getInitialState(),
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: name,
      invoke: {
        src: createFetchMachine(fulcrumFetchConfig),
        id: `${name}/fetch`,
      },
      initial: 'idle',
      states: {
        idle: {
          entry: 'removeTriggerFromContext',
          on: {
            LOG_EVENT: {
              target: 'evaluating',
            },
          },
        },
        evaluating: {
          entry: ['assignFulcrumContext', 'assignTriggerToContext'],
          always: [
            {
              target: 'logging',
              cond: 'isValidFulcrumEvent',
            },
            {
              target: 'redirecting',
              cond: 'isOutboundClick',
            },
            {
              target: 'idle',
            },
          ],
        },
        logging: {
          entry: 'logEvent',
          on: {
            FETCH_COMPLETE: {
              target: 'success',
            },
            FETCH_ERROR: {
              target: 'error',
            },
          },
        },
        success: {
          entry: 'assignWorkflowToContext',
          always: [
            {
              target: 'redirecting',
              cond: 'isOutboundClick',
            },
            {
              target: 'idle',
            },
          ],
        },
        error: {
          entry: 'reportError',
          always: {
            target: 'idle',
          },
        },
        redirecting: {
          invoke: {
            src: 'redirect',
          },
        },
      },
    },
    {
      guards: {
        isOutboundClick: (context) => {
          return Boolean(context.route);
        },
        isValidFulcrumEvent: (context) => {
          return (
            Boolean(context.trigger) &&
            !context.workflows[context.trigger.workflowId]
          );
        },
      },
      services: {
        redirect: (context) => {
          const { route } = context;

          if (route) {
            requestIdleCallback(() => {
              window.location = route.url;
            });
          }
        },
      },
      actions: {
        removeTriggerFromContext: assign({
          trigger: undefined,
        }),
        assignWorkflowToContext: assign({
          workflows: (context, _event) => {
            return {
              ...context.workflows,
              [context.trigger.workflowId]: true,
            };
          },
        }),
        assignFulcrumContext: assign({
          user: (_context, event) => {
            const [session] = event.payload.arguments;

            return session.user;
          },
          bank: (_context, event) => {
            const [_, bank] = event.payload.arguments;

            return createBank(bank);
          },
          trackable: (_context, event) => {
            const [_session, _bank, trackable] = event.payload.arguments;

            return trackable;
          },
          route: (_context, event) => {
            const [_session, _bank, _trackable, route] =
              event.payload.arguments;

            return route;
          },
        }),
        assignTriggerToContext: assign({
          trigger: (context) => {
            return evaluateFulcrumEvent(context);
          },
        }),
        logEvent: send(
          (context, _event) => {
            return {
              type: 'FETCH',
              user: context.user,
              fetchData: {
                bank: context.bank,
                trackable: context.trackable,
                trigger: context.trigger,
              },
              endpoint: '/k/handle-trigger',
            };
          },
          { to: `${name}/fetch` },
        ),
      },
    },
  );
};
