import { createMachine, send, sendParent, assign } from 'xstate';
import { createCaptureMachine } from './lead-capture-machine';
import { createTransitionMachine } from './lead-transition-machine';

import { EVENTS } from '../constants/analtyics-events';

import {
  constructEvent,
  constructDataLayer,
} from '../context/funnel-analytics-provider';

const checkShouldLogConversion = (context) => {
  // If we have a new lead, the "fcid" will be stored in the context's "transitionData"
  return context.transitionData && context.transitionData.fcid;
};

export const createLeadMachine = (name, config = {}) => {
  /** @xstate-layout N4IgpgJg5mDOIC5QBswEMIFoC2aDGAFgJYB2YAdERKgMQDKAqgEICyAkgCoDaADALqJQABwD2sIgBciIkoJAAPRJgBMAdgCc5HuoBsAZmUAWXQFYTOnqoA0IAJ6IAjHp3kAHPr2unh16oeGeHQBfIJtUDBx8YjJKajAaDgAlAEEAOTpONgB5VN4BJBBRcSkZOUUEZT0Tcj1TcwtlfwDrO0RDWvJ1I0b1EwcdE2cQsPQsXEJSCjw0IQkAVwAnUigaABkAUWSAEQB9AGFkgAUOBkT1rby5IslpWQLyjWr1dW91Hgd1Z3UW+wq9VRqOnUvh4ahMPEMfWGIHCYyik3I01mi2Wa02uwOx1O6x260SiSyiUuBWuJTuoHKZk0n3MXT8DhMXkMNl+mB0Di0rl6ej0DmUFkZg2hsMiExiEgWaBIxVuqLoAE1Ujs6OtUrsMgBxJVsJWrHUAaR2HCyuJYyTYq2JwjEN1K90cDn85HaZh4ekMqh4Jh8rhZiFUrnI4Pa7yqXp47kMwtGouiFAlUplMlRyT2hvWZotyrYWp2Op2etShpVaqthRtZLKjmcLj5ylBymeeghjr9FQcAIcgT5ri8FkMjWjEXGcfICeltpIcsV2dz+bSOXlLCyDDoq3lZdJtyrCCcXMBqhMfkG2hMyjbhgsNUbz0MkJ8+mUQ7hYvjkonZJTadn2qVC9SS4rmuG78FcFbbvau5OJo2iqDohgOO4yjuK4zKtAgOgaOQyGNH4riDL0rh6M+sYIuOSZTiQKxJGkGQcNkSp7FkLCHBsHDrJu4F2hSiBeHoWjPDwlgGIEVS+uhQI8Jy7ovEYKHqCRI5ke+FGojR6SZDkuL4oSnEUTulRuLU2j8oeziuI257oUehg1L4Lweo2XxPqEMIxkpMRgAsCwiAs9DMOw3CgSSXHkgoiC8sonIRgyqgIeoA7mG2-LkKoyjepCOhEQGj6KfCMSwBIaBQKiemTjuHz8ToyE8j4XZeKhbaqM2QYWBC7Iep8zx5a+5CFcVpUOPk1r6ZB3TYfocVmJ48EGG2XL8a4gSMtVzUdg4PWjgmeAANalcFI3lZBKiHp0mE1aCATmD8-oeuQjrKJUZg6BYAYKa5IoeRQsBzHgeBwLANBnCqQXDeWo08QgmCQlJ7xOPoZ7+OdTWoeQ7Ids8L19C8wTQiQIgQHAcifflFBUKgYEQ+FUPNpoNaNLyJgJXeJjJVejyNPBdUCptCJIvMSxUZTR2Q+0gaguYyG+M2fRVMld7YY9zTVV2Zi9rz4oqZOyzC5WkHPByDOeP0jaIUl6G1E83T9LN7r+BrFBeT5Cy6xBkNAmjXpEf4lg9royVRR2eFAtjtS5R97mk31RUlULIVU+Ul4ArozxGB6mFHk17o1Ah-RvG6l7uA7Y6SrtOvxyL1OYAytaPbUKdLRoFltkCHJvVlajAneFnFz9f0A673FV7TgIq4zzOQm2wKaEtAxIWtfgO4PYXlNDwJaI6zhVI0SdWayj3VBG3Jw1doIhCEQA */
  return createMachine(
    {
      context: {
        // User + Visitor details
        session: undefined,

        // The current page
        page: undefined,

        // The lead form installed on the current page (optional)
        form: undefined,

        // Data from the form submission
        formData: undefined,

        // Data returned from the "capture phase"
        captureData: undefined,

        // Data returned from the "transition phase"
        transitionData: undefined,

        // An error that we've bubbled up from the "capture" or "transition" phases
        error: undefined,
      },
      preserveActionOrder: true,
      predictableActionArguments: true,
      invoke: [
        {
          src: createCaptureMachine(config?.children?.capture || {}),
          id: 'capture',
        },
        {
          src: createTransitionMachine(config?.children?.transition || {}),
          id: 'transition',
        },
      ],
      id: 'lead-machine',
      initial: 'idle',
      states: {
        idle: {
          on: {
            SUBMIT: {
              target: 'capturing',
              actions: 'assignInitialContext',
            },
            TRANSITION: {
              target: 'transitioning',
              actions: 'assignInitialContext',
            },
          },
        },
        capturing: {
          entry: ['reportCapturePhaseBegin', 'startCapture'],
          on: {
            LEAD_CAPTURED: {
              target: 'transitioning',
              actions: [
                'assignCaptureDataToContext',
                'reportCapturePhaseComplete',
              ],
            },
            LEAD_CAPTURE_ERROR: {
              target: 'error',
              actions: ['assignErrorToContext', 'reportCapturePhaseError'],
            },
          },
        },
        transitioning: {
          entry: 'startTransition',
          on: {
            SYN_SEND_SIGN_IN_LINK_TO_EMAIL: {
              actions: 'synSendSignInLinkToEmail',
            },
            ACK_EMAIL_SIGN_IN_LINK_SEND: {
              actions: 'synAckSendSignInLinkToEmail',
            },
            SYN_SIGN_IN_ANONYMOUSLY: {
              actions: 'synSignInAnonymously',
            },
            ACK_SIGN_IN_ANONYMOUSLY: {
              actions: 'synAckSignInAnonymously',
            },
            TRANSITION_COMPLETE: {
              target: 'staging',
              actions: 'assignTransitionDataToContext',
            },
            TRANSITION_ERROR: {
              target: 'error',
              actions: ['assignErrorToContext', 'reportTransitionPhaseError'],
            },
          },
        },
        error: {
          on: {
            SUBMIT: {
              target: 'capturing',
              actions: ['removeErrorFromContext', 'assignInitialContext'],
            },
          },
        },
        staging: {
          always: [
            {
              target: 'tracking',
              cond: 'shouldLogConversion',
            },
            {
              target: 'success',
            },
          ],
        },
        tracking: {
          entry: 'reportGenerateLead',
          always: {
            target: 'success',
          },
        },
        success: {
          on: {
            RESET: {
              target: 'idle',
            },
          },
        },
      },
    },
    {
      actions: {
        assignErrorToContext: assign({
          error: (_, event) => {
            return event.error;
          },
        }),
        assignCaptureDataToContext: assign({
          captureData: (_context, event) => {
            return event.captureData;
          },
        }),
        assignTransitionDataToContext: assign({
          transitionData: (_context, event) => {
            return event.transitionData;
          },
        }),
        assignInitialContext: assign({
          session: (_context, event) => {
            return event.session;
          },
          page: (_context, event) => {
            return event.page;
          },
          form: (_context, event) => {
            return (
              event.page.forms.find((form) => form.action === 'lead') || {}
            );
          },
          formData: (_context, event) => {
            return event.formData;
          },
        }),
        removeErrorFromContext: assign({
          error: undefined,
        }),
        synSendSignInLinkToEmail: sendParent((_context, event) => {
          return {
            type: 'SERVICE.CALL',
            fromId: name,
            serviceId: 'auth',
            event: 'SEND_SIGN_IN_LINK_TO_EMAIL',
            arguments: [event.settings],
          };
        }),
        synAckSendSignInLinkToEmail: send(
          () => {
            return {
              type: 'SYN_ACK_SEND_SIGN_IN_LINK_TO_EMAIL',
            };
          },
          { to: 'transition' },
        ),
        synSignInAnonymously: sendParent((_context, _event) => {
          return {
            type: 'SERVICE.CALL',
            serviceId: 'auth',
            event: 'SIGN_IN_ANONYMOUSLY',
            arguments: [],
          };
        }),
        synAckSignInAnonymously: send(
          () => {
            return {
              type: 'SYN_ACK_SIGN_IN_ANONYMOUSLY',
            };
          },
          { to: 'transition' },
        ),
        startCapture: send(
          (context, _event) => {
            return {
              type: 'START',
              session: context.session,
              formData: context.formData,
            };
          },
          { to: 'capture' },
        ),
        startTransition: send(
          (context, event) => {
            return {
              type: 'START',
              page: context.page,
              session: context.session,
              formData: context.formData,
              captureData: event.captureData,
            };
          },
          { to: 'transition' },
        ),
        reportCapturePhaseBegin: sendParent((context) => {
          return {
            type: 'SERVICE.CALL',
            serviceId: 'analytics',
            event: 'LOG_EVENT',
            arguments: constructEvent(EVENTS.FUNNEL_FORM_SUBMIT, {
              params: {
                form_id: context.form?.formId || null,
                lead_source_id: context.form?.leadSourceId || null,
              },
              visitor: context.session.visitor,
              dataLayer: constructDataLayer(context.page),
            }),
          };
        }),
        reportCapturePhaseComplete: sendParent((context) => {
          return {
            type: 'SERVICE.CALL',
            serviceId: 'analytics',
            event: 'LOG_EVENT',
            arguments: constructEvent(EVENTS.FUNNEL_FORM_SUBMIT_SUCCESS, {
              params: {
                form_id: context.form?.formId || null,
                lead_source_id: context.form?.leadSourceId || null,
              },
              visitor: context.session.visitor,
              dataLayer: constructDataLayer(context.page),
            }),
          };
        }),
        reportCapturePhaseError: sendParent((context) => {
          return {
            type: 'SERVICE.CALL',
            serviceId: 'analytics',
            event: 'LOG_EVENT',
            arguments: constructEvent(EVENTS.FUNNEL_FORM_SUBMIT_ERROR, {
              params: {
                form_id: context.form?.formId || null,
                lead_source_id: context.form?.leadSourceId || null,
                phase: 'capture',
              },
              visitor: context.session.visitor,
              dataLayer: constructDataLayer(context.page),
            }),
          };
        }),
        reportTransitionPhaseError: sendParent((context) => {
          return {
            type: 'SERVICE.CALL',
            serviceId: 'analytics',
            event: 'LOG_EVENT',
            arguments: constructEvent(EVENTS.FUNNEL_FORM_SUBMIT_ERROR, {
              params: {
                form_id: context.form?.formId || null,
                lead_source_id: context.form?.leadSourceId || null,
                phase: 'transition',
              },
              visitor: context.session.visitor,
              dataLayer: constructDataLayer(context.page),
            }),
          };
        }),
        reportGenerateLead: sendParent((context) => {
          return {
            type: 'SERVICE.CALL',
            serviceId: 'analytics',
            event: 'LOG_EVENT',
            arguments: constructEvent(EVENTS.GENERATE_LEAD, {
              visitor: context.session.visitor,
              dataLayer: constructDataLayer(context.page),
            }),
          };
        }),
        ...config?.main?.actions,
      },
      services: {
        ...config?.main?.services,
      },
      guards: {
        shouldLogConversion: checkShouldLogConversion,
        ...config?.main?.guards,
      },
    },
  );
};
