import React, { createContext, useContext } from 'react';

export const HeadlessContext = createContext([]);

export const selectMetatagNoindex = (meta) => meta.key === 'noindex';
export const selectMetatagTitle = (meta) => meta.key === 'title';

export const selectCustomModule = (context) => {
  return context.display.find((entity) => {
    return Boolean(entity.custom?.name);
  });
};

export const selectBackgroundImages = (context) => {
  return context.mediaAssets.filter((entity) => {
    return entity.role === 'image/background';
  });
};

export const selectBackgroundImage = (context) => {
  return context.mediaAssets.find((entity) => {
    return entity.role === 'image/background';
  });
};

export const selectProductImage = (context) => {
  return context.mediaAssets.find((entity) => {
    return entity.role === 'image/product';
  });
};

export const selectOutboundCallToAction = (context) => {
  return context.inputs.find((d) => {
    return d.role === 'call-to-action/outbound';
  });
};

const mapToProps = (dataset, keygen = (attr) => attr.key) => {
  if (Array.isArray(dataset)) {
    return dataset.reduce((acc, attr) => {
      return {
        ...acc,
        [`${keygen(attr)}`]: attr.value,
      };
    }, {});
  }
  return dataset;
};

const mapDatasetToObject = (dataset) => {
  return mapToProps(dataset, (attr) => `data-${attr.key}`);
};

const mapHeadlessJsonKeyToObject = (collection, key) => {
  return collection.map((entity) => {
    return {
      ...entity,
      [key]: JSON.parse(entity[key]),
    };
  });
};

const mapHeadlessComponentCollection = (collection) => {
  return collection.map((entity) => ({
    entityId: entity.entityId,
    component: entity.component,
    id: entity.componentId,
    role: entity.componentRole,
    props: mapToProps(JSON.parse(entity.componentProps)),
    content: JSON.parse(entity.componentContent),
    children: JSON.parse(entity.componentChildren),
    dataset: mapDatasetToObject(JSON.parse(entity.componentDataset)),
    custom: JSON.parse(entity.componentCustom),
  }));
};

export const HeadlessProvider = ({ children, data = {} }) => {
  const { kalansoPage = {} } = data;
  const {
    forms = [],
    display = [],
    inputs = [],
    settings = [],
    metatags = [],
    triggers = [],
    mediaAssets = [],
    routes = [],
  } = kalansoPage;

  const proxy = {
    triggers,
    metatags,
    routes,
    mediaAssets: mapHeadlessJsonKeyToObject(mediaAssets, 'metatags'),
    settings: mapHeadlessJsonKeyToObject(settings, 'value'),
    display: mapHeadlessComponentCollection(display),
    inputs: mapHeadlessComponentCollection(inputs),
    forms: forms.map((f) => ({
      ...f,
      display: mapHeadlessComponentCollection(JSON.parse(f.display)),
      inputs: mapHeadlessComponentCollection(JSON.parse(f.inputs)),
    })),
  };

  return (
    <HeadlessContext.Provider value={proxy}>
      {children}
    </HeadlessContext.Provider>
  );
};

const toObject = (result, variant) => {
  return {
    ...result,
    content: (Array.isArray(result.content) ? result.content : []).find((c) => {
      if (c.variant !== undefined) {
        return c.variant === variant;
      }
      return true;
    }),
    props: Array.isArray(result.props)
      ? mapToProps(result.props)
      : result.props,
  };
};

const select = (context, selector, variant) => {
  if (!selector) {
    return context;
  }

  let result;

  if (typeof selector === 'function') {
    result = selector(context, variant);
  } else if (context[selector]) {
    result = context[selector];
  }

  if (Array.isArray(result)) {
    return result.map((r) => toObject(r, variant));
  }

  if (!result) {
    return;
  }

  return toObject(result, variant);
};

export const useHeadless = (selector, variant = 0) => {
  const context = useContext(HeadlessContext);

  return select(context, selector, variant);
};

export const useHeadlessContext = (context, selector, variant = 0) => {
  return select(context, selector, variant);
};
