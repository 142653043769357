import React, { createContext, useContext } from 'react';

import { SupervisorContext } from './supervisor-provider';

import { createActionAnalytics } from './firebase-analytics-provider';

import { EVENTS } from '../constants/analtyics-events';

import toSnakeCase from '../components/shared/to-snake-case';

export const toRoundedPercent = (percent) => Math.floor(percent * 100);

export const constructDataLayer = (page = {}) => {
  const { analytics = {} } = page;
  const { branch, environment, host, platform, funnelId, pageId, packId } =
    analytics;

  const dataLayer = {
    branch,
    environment,
    host,
    platform,
    // We're keeping the camel casing here because GA4 expects these
    // variables to be camel cased.
    funnel_id: funnelId,
    page_id: pageId,
    pack_id: packId,
  };

  return dataLayer;
};

export const FunnelAnalyticsContext = createContext(null);

export const FunnelAnalyticsProvider = ({ children, data = {} }) => {
  const { kalansoPage = {} } = data;
  const supervisor = useContext(SupervisorContext);

  const dataLayer = constructDataLayer(kalansoPage);

  return (
    <FunnelAnalyticsContext.Provider
      value={{ dataLayer, supervisor: supervisor.service }}
    >
      {children}
    </FunnelAnalyticsContext.Provider>
  );
};

const constructEventExperimentView = ({
  visitor,
  experiment,
  result,
  dataLayer,
}) => {
  const attributes = {
    ...dataLayer,
    lh_experiment_id: experiment.key,
    lh_variation_id: result.variationId,
    lh_variation_value: result.value,
    [visitor.key]: visitor.identifier,
  };

  return [EVENTS.EXPERIMENT_VIEW, attributes, {}];
};

const constructEventFunnelPageView = ({ location, visitor, dataLayer }) => {
  const attributes = {
    ...dataLayer,
  };

  if (visitor) {
    attributes[visitor.key] = visitor.identifier;
  }

  attributes.page_url = location.href;
  attributes.page_hostname = location.host;
  attributes.page_path = location.pathname;
  attributes.page_search = location.search;
  attributes.page_title = document.title;
  attributes.referrer = document.referrer;

  return [EVENTS.FUNNEL_PAGE_VIEW, attributes, {}];
};

const constructEventFunnelClick = ({ target, visitor, dataLayer }) => {
  const attributes = {
    ...dataLayer,
  };

  if (visitor) {
    attributes[visitor.key] = visitor.identifier;
  }

  for (const key in target.dataset) {
    attributes[toSnakeCase(key)] = target.dataset[key];
  }

  // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/innerText
  attributes.click_text = target.textContent;

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement/href
  if (target.href || attributes['outbound_click']) {
    const dest = new URL(target.href || attributes.click_href);
    if (!attributes['click_herf']) {
      attributes['click_href'] = dest.href;
    }
    if (!attributes['click_origin']) {
      attributes['click_origin'] = dest.origin;
    }
    if (!attributes['click_hostname']) {
      attributes['click_hostname'] = dest.hostname;
    }
    if (window.location?.origin !== dest?.origin) {
      attributes.outbound = true;
    }
  }

  return [EVENTS.FUNNEL_CLICK, attributes, {}];
};

const constructEventEcommerce = ({
  eventName,
  params,
  visitor,
  dataLayer,
  ecommData,
}) => {
  if (!EVENTS[eventName]) {
    throw new Error(`Invalid event: ${eventName}`);
  }

  const attributes = {
    ...dataLayer,
    ...params,
  };

  if (visitor) {
    attributes[visitor.key] = visitor.identifier;
  }

  const {
    item_id,
    item_name,
    coupon,
    discount,
    index,
    quantity,
    price,
    currency,
    highlight,
  } = ecommData;

  attributes.items = [
    {
      item_id,
      item_name,
      coupon,
      discount,
      index,
      quantity,
      price,
      currency,
    },
  ];

  attributes.highlight = highlight;

  return [EVENTS[eventName], attributes, {}];
};

const constructEventDefault = ({ eventName, params, visitor, dataLayer }) => {
  if (!EVENTS[eventName]) {
    throw new Error(`Invalid event: ${eventName}`);
  }

  const attributes = {
    ...dataLayer,
    ...params,
  };

  if (visitor) {
    attributes[visitor.key] = visitor.identifier;
  }

  return [EVENTS[eventName], attributes, {}];
};

export const constructEvent = (eventName, payload) => {
  switch (eventName) {
    case EVENTS.FUNNEL_PAGE_VIEW:
      return constructEventFunnelPageView({ eventName, ...payload });
    case EVENTS.EXPERIMENT_VIEW:
      return constructEventExperimentView({ eventName, ...payload });
    case EVENTS.FUNNEL_CLICK:
      return constructEventFunnelClick({ eventName, ...payload });
    case EVENTS.SELECT_ITEM:
    case EVENTS.BEGIN_CHECKOUT:
      return constructEventEcommerce({ eventName, ...payload });
    default:
      return constructEventDefault({ eventName, ...payload });
  }
};

export const useFunnelAnalytics = () => {
  const context = useContext(FunnelAnalyticsContext);

  if (context === undefined) {
    throw new Error(
      `useFunnelAnalytics may only be used withing a FunnelAnalyticsContext`,
    );
  }

  const logEvent = (eventName, payload) => {
    const event = constructEvent(eventName, {
      ...payload,
      dataLayer: context.dataLayer,
    });
    context.supervisor.send(createActionAnalytics('LOG_EVENT', ...event));
  };

  const logVideoView = (payload) => {
    const { event, media } = payload;
    const { percent, duration, seconds } = event;
    const breakpoints = [1, 2, 7, 10, 15, 25, 50, 75, 95, 100];
    const rounded = toRoundedPercent(percent);

    if (breakpoints.includes(rounded)) {
      const params = {
        video_current_time: seconds,
        video_duration: duration,
        video_percent: percent,
        video_provider: media.provider,
        media_id: media.mediaId,
      };

      switch (rounded) {
        case 1:
          logEvent(EVENTS.VIDEO_START, { params });
          break;

        case 2:
        case 7:
        case 10:
        case 15:
        case 25:
        case 50:
        case 75:
        case 95:
          logEvent(EVENTS.VIDEO_PROGRESS, { params });
          break;

        case 100:
          logEvent(EVENTS.VIDEO_COMPLETE, { params });
          break;

        default:
          throw new Error(`Video Progress Not Logged: ${rounded}`);
      }
    }
  };

  const actions = {
    logEvent,
    logVideoView,
  };

  return { actions };
};
