import { createMachine, sendParent, send, assign } from 'xstate';

import { createFetchMachine } from './fetch-machine';

const formatRequestBody = (context) => {
  const { captchaToken, fetchData } = context;

  const body = {
    k: {
      ...fetchData,
      rt: captchaToken,
    }, // Required
    s: {}, // Optional
    o: {}, // Tracking
  };

  return JSON.stringify(body);
};

export const createCaptureMachine = (config = {}) => {
  /** @xstate-layout N4IgpgJg5mDOIC5QBswEMIFoDGaAOALgK4BOYAdAJYSoDEAygCoCCASowNoAMAuoqHgD2sSgUqCAdvxAAPRAFYATAHZyXAMwBOLovXLl6xYoCM8gDQgAnogAsADhvlN8u-PnHdHo-oC+Pi6gYOPjEZOQAZmAE2AAWlBJQtABiAKKMAMIAEgD66QDyALIACgAyaSncfEggQiJiktJyCMbG6nZqznaaAGzK8urqxg4W1gjdNsZqKjbyM4p23eM2fgHoWLiEpBSR0XEJyWlZ2SmsrHmsldK1ouJS1U2zXOSKmnYeyotKM8ojiOrj5AGNkUwPkmgmyley38IEC6xCW3IG1CkFol2q13qd1ATRM3UBMw0xmBXHkH3k3V+CGUE3I3TeZMU3V02k0fhhEkEEDg0jhwU2YWoqHInJSJBIghIV2ENwa90QPSp9nU5DsRhs4O6mkGXD6K1ha35KKoNAoYHFksg0rqt0aiBU7QmWvmvRsOk0VLaqjVIM12uMuvk+r5yMRQrA1tl2NktjsVJe7R99leQy4dlJ3WDhtDYR2sXiUEjWLtCG18icj2U6e1i2cVPcKvGpjVNmUXC4buUWaCOYovYgRdt8rGbVVNhsgzs-y1WnUVIMmjUHcG5NmIOM3fhAojGJlxeHXCpxm6kx98i4mhU7cvdnZPiAA */
  return createMachine(
    {
      context: {
        session: undefined,
        error: undefined,
        formData: undefined,
        ...config.context,
      },
      preserveActionOrder: true,
      predictableActionArguments: true,
      invoke: {
        src: createFetchMachine({
          main: {
            ...config?.children?.fetch?.main,
            actions: {
              ...config?.children?.fetch?.main?.actions,
              assignRequestBodyToContext: assign({
                requestBody: formatRequestBody,
              }),
            },
          },
        }),
        id: 'capture-fetch',
      },
      id: 'lead-capture',
      initial: 'idle',
      states: {
        idle: {
          initial: 'noError',
          states: {
            noError: {
              entry: 'removeErrorFromContext',
            },
            errored: {},
          },
          on: {
            START: {
              target: 'fetching',
              actions: ['assignFormDataToContext', 'assignSessionToContext'],
            },
          },
        },
        fetching: {
          entry: 'fetch',
          on: {
            FETCH_COMPLETE: {
              target: 'idle',
              actions: ['sendServerDataToParent'],
            },
            FETCH_ERROR: {
              target: 'idle.errored',
              actions: 'sendServerErrorToParent',
            },
          },
        },
      },
    },
    {
      actions: {
        removeErrorFromContext: assign({
          error: undefined,
        }),
        assignFormDataToContext: assign({
          formData: (_context, event) => {
            return event.formData;
          },
        }),
        assignSessionToContext: assign({
          session: (_context, event) => {
            return event.session;
          },
        }),
        fetch: send(
          (context, _event) => {
            return {
              type: 'FETCH',
              user: context.session.user,
              fetchData: context.formData,
              endpoint: '/k/submit-lead-form',
            };
          },
          { to: 'capture-fetch' },
        ),
        sendServerErrorToParent: sendParent((_, event) => {
          return {
            type: 'LEAD_CAPTURE_ERROR',
            error: event.serverData,
          };
        }),
        sendServerDataToParent: sendParent((_, event) => {
          return { type: 'LEAD_CAPTURED', captureData: event.serverData };
        }),
        ...config?.main?.actions,
      },
      services: {
        ...config?.main?.services,
      },
      guards: {
        ...config?.main?.guards,
      },
    },
  );
};
