import React, { createContext, useContext } from 'react';
import { useSelector } from '@xstate/react';

import { SupervisorContext } from './supervisor-provider';

// Selectors
const selectCurrentValue = (state) => state.value;
const selectAnalyticsService = (state) => state.children['analytics'];

export const createActionAnalytics = (event, ...args) => ({
  type: 'SERVICE.CALL',
  serviceId: 'analytics',
  event,
  arguments: [...args],
});

export const FirebaseAnalyticsContext = createContext();

export const FirebaseAnalyticsProvider = (props) => {
  const supervisor = useContext(SupervisorContext);
  const service = useSelector(supervisor.service, selectAnalyticsService);

  return (
    <FirebaseAnalyticsContext.Provider
      value={{
        supervisor: supervisor.service,
        service,
      }}
    >
      {props.children}
    </FirebaseAnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(FirebaseAnalyticsContext);
  const value = useSelector(context.service, selectCurrentValue);

  const logEvent = (...args) => {
    context.supervisor.send(createActionAnalytics('LOG_EVENT', ...args));
  };

  const setUserId = (...args) => {
    context.supervisor.send(createActionAnalytics('SET_USER_ID', ...args));
  };

  const actions = { logEvent, setUserId };

  return { actions, value };
};
