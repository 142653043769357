import { createMachine, sendParent, assign } from 'xstate';
import { requestIdleCallback } from '../components/shared/request-idle-callback-shim';

import { EVENTS } from '../constants/analtyics-events';

import {
  constructEvent,
  constructDataLayer,
} from '../context/funnel-analytics-provider';

export const createOutboundCheckoutMachine = (name) => {
  /** @xstate-layout N4IgpgJg5mDOIC5QHsCuAXARmgdhAtAMYAWYhA1mugHQCWEANmAMQBCAogOICSAcgPoBhABLtBAaQDyAVQAqAbQAMAXUSgADsli10tZDjUgAHogAcAZgBs1SwHZz5xeYCcDywEYXAGhABPROYALM7UzoqBAExRAKxWloqmngC+ST5U2Kh4RKQUVNSYYFC0ODjFUII5lBjMSqpIIJrauvqGJgiuptS2EbbRiraB9uGBgT7+CBbUitPT7u6WzhHuEYHRKWkYGVkkZFU0xACGeAxlkpu4EBW7VMwQ+mB0OABuyOQP6RfZ1xjUh8en50yl0qVAQxRehAOzRwtVqhkaOj0BnqbXMkxWtlspkCQQiplMHjGAW6XUcinczm62PmlnWIA+QK+uR+f0YAKwFyuzPQbC4fCEogkMgUKnhWkRLRRAXRgyxOMi+MJfkQEUU0SmLiW5gi0Vs03iazpOGQEDghgZ2xBGDFTSRrUQ+EsRIQjqmM3c0Us2op7mGdItBB23LojDANolyNAbV67mogQ8EWcXpWiii5md5ns1AiZKcdll-sBlu+NAKRRKZS5e3D0PtCF90XVdl1BOx3UUrgzEWstni-Xj0Ui7gs5kLHMZQb2vyObJwUDO47wVaoNbtUoQkWdqtj9ncOL6gXmDmiERSKSAA */
  return createMachine(
    {
      context: {
        session: undefined,
        page: undefined,
        checkoutUrl: undefined,
        ecommData: undefined,
      },
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: 'outbound-checkout',
      initial: 'idle',
      states: {
        idle: {
          on: {
            BEGIN_CHECKOUT: {
              target: 'beginningCheckout',
              actions: ['assignDataToContext'],
            },
          },
        },
        beginningCheckout: {
          entry: 'reportOutboundCheckoutBegin',
          always: {
            target: 'handlingOutboundCheckout',
          },
        },
        handlingOutboundCheckout: {
          on: {
            BEGIN_CHECKOUT: {
              target: 'beginningCheckout',
              actions: ['assignDataToContext'],
            },
          },
          invoke: {
            src: 'handleOutboundCheckoutBegin',
          },
        },
      },
    },
    {
      actions: {
        assignDataToContext: assign({
          page: (_context, event) => {
            const [{ page }] = event.payload.arguments;
            return page;
          },
          session: (_context, event) => {
            const [{ session }] = event.payload.arguments;
            return session;
          },
          checkoutUrl: (_context, event) => {
            const [{ checkoutUrl }] = event.payload.arguments;
            return checkoutUrl;
          },
          ecommData: (_context, event) => {
            const [{ ecommData }] = event.payload.arguments;
            return ecommData;
          },
        }),
        reportOutboundCheckoutBegin: sendParent((context) => {
          const checkout = new URL(context.checkoutUrl);

          return {
            type: 'SERVICE.CALL',
            serviceId: 'analytics',
            event: 'LOG_EVENT',
            arguments: constructEvent(EVENTS.BEGIN_CHECKOUT, {
              params: {
                checkout_url: checkout.href,
                checkout_host: checkout.host,
                outbound: true,
              },
              visitor: context.session.visitor,
              dataLayer: constructDataLayer(context.page),
              ecommData: context.ecommData,
            }),
          };
        }),
      },
      services: {
        handleOutboundCheckoutBegin: (context) => {
          requestIdleCallback(() => {
            window.location = context.checkoutUrl;
          });
          return Promise.resolve();
        },
      },
      guards: {},
    },
  );
};
