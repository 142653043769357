import React, { createContext, useContext } from 'react';

import { SupervisorContext } from './supervisor-provider';

import { useSelector } from '@xstate/react';

export const enhanceBankWithHtmlDataAttributes = (bank, eventTarget) => {
  return {
    ...bank,
    ...Object.entries(eventTarget.dataset).reduce((acc, [key, value]) => {
      return { ...acc, [`client.event.target.dataset.${key}`]: value };
    }, {}),
  };
};

export const enhanceBankWithSessionDetails = (bank, session) => {
  return {
    ...bank,
    'session.visitor': {
      [session?.visitor?.key]: session?.visitor?.identifier,
    },
    'session.user': {
      email: session?.user?.email || '',
      is_anonymous: Boolean(session?.user?.isAnonymous),
      uid: session?.user?.uid || '',
    },
    'session.funnel': session?.contact,
  };
};

export const createActionFulcrum = (serviceId, event, ...args) => ({
  type: 'SERVICE.CALL',
  serviceId: serviceId,
  event,
  arguments: [...args],
});

export const FulcrumContext = createContext();

const selectFulcrumPageService = (state) => state.children['fulcrum/page'];
const selectFulcrumClickService = (state) => state.children['fulcrum/click'];
const selectFulcrumMediaService = (state) => state.children['fulcrum/media'];

export const FulcrumProvider = (props) => {
  const supervisor = useContext(SupervisorContext);
  const pageService = useSelector(supervisor.service, selectFulcrumPageService);
  const clickService = useSelector(
    supervisor.service,
    selectFulcrumClickService,
  );
  const mediaService = useSelector(
    supervisor.service,
    selectFulcrumMediaService,
  );

  return (
    <FulcrumContext.Provider
      value={{
        supervisor: supervisor.service,
        pageService,
        clickService,
        mediaService,
      }}
    >
      {props.children}
    </FulcrumContext.Provider>
  );
};

export const useFulcrumPageView = () => {
  const context = useContext(FulcrumContext);

  if (context === undefined) {
    throw new Error(
      `useFulcrumPageView may only be used within a FulcrumContext`,
    );
  }

  const logPageView = (...args) => {
    context.supervisor.send(
      createActionFulcrum('fulcrum/page', 'LOG_EVENT', ...args),
    );
  };

  const actions = {
    logPageView,
  };

  return {
    actions,
    service: context.pageService,
  };
};

export const useFulcrumClick = () => {
  const context = useContext(FulcrumContext);

  if (context === undefined) {
    throw new Error(`useFulcrumClick may only be used within a FulcrumContext`);
  }

  const logClick = (...args) => {
    context.supervisor.send(
      createActionFulcrum('fulcrum/click', 'LOG_EVENT', ...args),
    );
  };

  const actions = {
    logClick,
  };

  return {
    actions,
    service: context.clickService,
  };
};

export const useFulcrumMedia = () => {
  const context = useContext(FulcrumContext);

  if (context === undefined) {
    throw new Error(`useFulcrumMedia may only be used within a FulcrumContext`);
  }

  const logVideoView = (...args) => {
    context.supervisor.send(
      createActionFulcrum('fulcrum/media', 'LOG_EVENT', ...args),
    );
  };

  const actions = {
    logVideoView,
  };

  return {
    actions,
    service: context.mediaService,
  };
};
