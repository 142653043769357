import { createMachine, sendParent } from 'xstate';

import { requestIdleCallback } from '../components/shared/request-idle-callback-shim';

const LAZY_LOADERS = {
  firebase: {
    import: () => import('../lazy/firebase-app'),
  },
  auth: {
    import: () => import('../lazy/firebase-auth'),
  },
  analytics: {
    import: () => import('../lazy/firebase-analytics'),
  },
  firestore: {
    import: () => import('../lazy/firebase-firestore'),
  },
  airbrake: {
    import: () => import('../lazy/airbrake'),
  },
};

export const createLoaderMachine = (serviceId) =>
  /** @xstate-layout N4IgpgJg5mDOIC5gF8A0IB2B7CdGgBssBDXAJwDoBLCAsfEABy1ioBcqsMGAPRAFgBM6AJ6IAHAGZkaEEVJgyDZqw5deiALQA2UVu0yZQA */
  createMachine(
    {
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: serviceId,
      initial: 'loading',
      context: { serviceId },
      states: {
        loading: {
          invoke: {
            src: 'load',
          },
          on: {
            LOADER_RESOLVED: {
              target: 'loaded',
              actions: sendParent((context, event) => ({
                type: 'LOADER_RESOLVED',
                data: {
                  ...event,
                  serviceId: context.serviceId,
                },
              })),
            },
          },
        },
        loaded: {
          type: 'final',
        },
      },
    },
    {
      services: {
        load: (context) => (send) => {
          requestIdleCallback(() => {
            LAZY_LOADERS[context.serviceId].import().then((mod) => {
              send({
                type: 'LOADER_RESOLVED', // STUB
                module: mod,
              });
            });
          });
        },
      },
    },
  );
