import React, { useContext, createContext, useEffect } from 'react';

import { SupervisorContext } from './supervisor-provider';
import { createActionAnalytics } from './firebase-analytics-provider';
import {
  FunnelAnalyticsContext,
  constructEvent,
} from './funnel-analytics-provider';
import { useLearningHouse } from './learning-house-provider';

import { EVENTS } from '../constants/analtyics-events';

const TIMEOUT_MS = 200;

export const PageViewContext = createContext({});

export const FunnelPageViewProvider = ({ children, location }) => {
  const supervisor = useContext(SupervisorContext);
  const { dataLayer } = useContext(FunnelAnalyticsContext);

  const { visitor } = useLearningHouse();

  useEffect(() => {
    const [eventName, eventParams, eventOptions] = constructEvent(
      EVENTS.FUNNEL_PAGE_VIEW,
      { location, visitor, dataLayer },
    );

    // We need a slight delay to account for the time it takes <Helmet />
    // to update the document head.
    const timer = setTimeout(() => {
      supervisor.service.send(
        createActionAnalytics(
          'LOG_EVENT',
          eventName,
          eventParams,
          eventOptions,
        ),
      );
    }, TIMEOUT_MS);

    return () => {
      clearTimeout(timer);
    };
  }, [location, visitor, dataLayer]);

  return (
    <PageViewContext.Provider value={{}}>{children}</PageViewContext.Provider>
  );
};
