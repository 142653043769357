import { createMachine } from 'xstate';

const airbrakeMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCWAnARu5BrMAdKhADZgDEAcgPIAqAkgGICaA2gAwC6ioADgPaxUAF1T8AdjxAAPRAEYArAHYCANgDMAFk1yATOw0aFATl0AaEAE9EuuewK6lhuauMAOPW6WaAvj4toWDj4BCT8UFCo4lDkEBKEUQBu-CGB2HiEYRFRUAhJ-ADGyKISHJxlUgJCJZJIMvLq9vqutm66pqrsphbWCAC0ugQKCppKSsZKbppuxnLq6k5+-iDi-BBwUmnBCaRglYIiYrWgsgia5laIxqpD7HfaugpzOnJufgEY6SFZkdH71UcpKddG4FA4ZuwlOxNKpQbpVHIeog3OoCHc7uo5JpjOpbO5VO8QFsMv9DhIgYg+qokf1ZkMRmMJlMZs8lj4gA */
  createMachine(
    {
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: 'airbrake',
      initial: 'idle',
      states: {
        idle: {
          on: {
            NOTIFY: {
              target: 'notifying',
            },
          },
        },
        notifying: {
          invoke: {
            src: 'notify',
            onDone: [
              {
                target: 'idle',
              },
            ],
          },
        },
      },
    },
    {
      services: {
        notify: (_context, event) => {
          const [eventParams] = event.payload.arguments;

          const { error, context, environment, params, session, page } =
            eventParams;

          const payload = {
            error,
            params,
            context: {
              ...context,
              pageId: page.pageId,
              packId: page.packId,
              funnelId: page.funnelId,
            },
            environment: {
              ...environment,
              branch: page.analytics.branch,
              env: page.analytics.environment,
              platform: page.analytics.platform,
              host: page.analytics.host,
            },
            session: {
              userId: session?.user?.uid,
              visitorId: session?.visitor?.identifier,
            },
          };

          if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
            console.group('Sending to Airbake:');
            console.log(payload);
            console.groupEnd();
          }

          return event.payload.instance.notify(payload);
        },
      },
    },
  );

export default airbrakeMachine;
