import {
  wrapRootElement as wrapRootElementCommon,
  wrapPageElement as wrapPageElementCommon,
} from './gatsby-common';

// import { inspect } from '@xstate/inspect';

// inspect({
//   // options
//   // url: 'https://stately.ai/viz?inspect', // (default)
//   iframe: false, // open in new window
// });

export const wrapRootElement = wrapRootElementCommon;

export const wrapPageElement = wrapPageElementCommon;
