import { createMachine } from "xstate"

const firebaseMachine = createMachine({
  preserveActionOrder: true,
  predictableActionArguments: true,
  id: "firebase",
  initial: "idle",
  states: {
    idle: {},
  },
})

export default firebaseMachine
