import React from 'react';

import { Script } from 'gatsby';

const ReCAPTCHA = () => {
  const url = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAP_PUB_KEY}`;

  return <Script src={url} strategy="idle" />;
};

export default ReCAPTCHA;
