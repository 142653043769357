import React, { createContext, useContext } from 'react';

export const CaptchaContext = createContext();

export const execute = (key, action) => {
  return new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() =>
        window.grecaptcha.execute(key, { action }).then(
          (token) => {
            resolve(token);
          },
          (err) => {
            console.log(err);
            reject(err);
          },
        ),
      );
    }
  });
};

/**
 * Allows us to generate reCAPTCHA V3 Tokens at will.
 *
 * The reCAPTCHA js is included via firebase.
 *
 */
export const CaptchaProvider = ({ children }) => {
  return (
    <CaptchaContext.Provider value={{}}>{children}</CaptchaContext.Provider>
  );
};

export const useCaptcha = () => {
  const context = useContext(CaptchaContext);

  if (context === undefined) {
    throw new Error(`useCaptcha must be used within a CaptchaContext`);
  }

  return { execute };
};
