import React, { createContext, useContext } from 'react';

import { SupervisorContext } from './supervisor-provider';

export const createActionAirbrake = (event, ...args) => ({
  type: 'SERVICE.CALL',
  serviceId: 'airbrake',
  event,
  arguments: [...args],
});

export const AirbrakeContext = createContext();

export const AirbrakeProvider = (props) => {
  const supervisor = useContext(SupervisorContext);

  return (
    <AirbrakeContext.Provider
      value={{
        supervisor: supervisor.service,
      }}
    >
      {props.children}
    </AirbrakeContext.Provider>
  );
};

export const useAirbrake = () => {
  const context = useContext(AirbrakeContext);

  const notify = (...args) => {
    context.supervisor.send(createActionAirbrake('NOTIFY', ...args));
  };

  const actions = { notify };

  return { actions };
};
