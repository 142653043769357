import React, { createContext, useEffect, useContext, useState } from 'react';

import { useSelector } from '@xstate/react';

import { SupervisorContext } from './supervisor-provider';

import { useAuth } from './firebase-auth-provider';

export const FunnelContactContext = createContext(null);

const selectFirestoreBundle = (state) =>
  state.context.installations['firestore']?.bundle;
const selectFirestoreImpl = (state) =>
  state.context.installations['firestore']?.instance;

export const FunnelContactProvider = ({ children, data = {} }) => {
  const { kalansoPage = {} } = data;
  const { packId } = kalansoPage;

  const supervisor = useContext(SupervisorContext);
  const bundle = useSelector(supervisor.service, selectFirestoreBundle);
  const impl = useSelector(supervisor.service, selectFirestoreImpl);
  const isSdkReady = Boolean(bundle) && Boolean(impl);

  const { user } = useAuth();

  const [contact, setContact] = useState(undefined);

  useEffect(() => {
    let unsubscribe;

    if (!isSdkReady || !user) {
      return;
    }

    const { query, collection, where, onSnapshot } = bundle;

    const q = query(
      collection(impl, 'funnel_contacts'),
      where('user_id', '==', user.uid),
      where('pack_id', '==', packId),
    );

    unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];

      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });

      if (docs.length === 1) {
        setContact(docs[0]);
      } else {
        setContact(null);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [isSdkReady, bundle, impl, supervisor, user, packId]);

  return (
    <FunnelContactContext.Provider
      value={{ contact, supervisor: supervisor.service }}
    >
      {children}
    </FunnelContactContext.Provider>
  );
};

export function useFunnelContact() {
  const context = useContext(FunnelContactContext);

  if (context === undefined) {
    throw new Error(
      'useFunnelContact must be used within a FunnelContactProvider',
    );
  }

  const prefetch = () => {
    context.supervisor.send('PREFETCH', {
      modules: [{ serviceId: 'firestore' }],
    });
  };

  const actions = {
    prefetch,
  };

  return {
    actions,
    contact: context.contact,
    synchronized: context.contact !== undefined,
  };
}
