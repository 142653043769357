import { createMachine,  } from "xstate"

const firestoreMachine = createMachine({
  preserveActionOrder: true,
  predictableActionArguments: true,
  id: "firestore",
  initial: "idle",
  states: {
    idle: {},
  },
})

export default firestoreMachine