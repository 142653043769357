import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Typography } from '@mui/material';
import Centered from './centered';

import { Centered as Logo } from './logo';

import Link from '../../components/shared/link';

const DefaultVariant = ({ page, text, error }) => {
  if (error) {
    const route = page
      ? page.routes.find((route) => route.name === 'unauthorized')
      : { url: '/' };

    return (
      <>
        <Logo style={{ width: '20rem' }} />
        <br />
        <br />
        <Typography align="center" variant="h5" paragraph={true}>
          Sorry. Your request could not be completed. You may try again{' '}
          <Link
            to={route.url}
            linkProps={{
              sx: { color: 'secondary.main', textDecoration: 'none' },
            }}
          >
            here
          </Link>
          .
        </Typography>
        <Alert sx={{ mt: '1rem' }} severity="error">
          {error?.message || 'Something went wrong.'}{' '}
          <em>(Code: {error?.code || 'Unknown'})</em>
        </Alert>
      </>
    );
  }

  return (
    <>
      <Logo style={{ width: '20rem' }} />
      <br />
      <br />
      <Typography align="center" variant="h5" paragraph={true}>
        {text}
      </Typography>
    </>
  );
};

const LoadingScreen = ({ page, variant, text, error }) => {
  return (
    <Centered>
      {variant === 'default' && (
        <DefaultVariant page={page} text={text} error={error} />
      )}
    </Centered>
  );
};

LoadingScreen.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string,
};

LoadingScreen.defaultProps = {
  variant: 'default',
  text: 'Loading...',
};

export default LoadingScreen;
