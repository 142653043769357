import React from 'react';

import { Script } from 'gatsby';

export const ClarityPixel = () => {
  if (!process.env.GATSBY_CLARITY_ID) {
    return null;
  }

  return (
    <Script
      id="clarity-pixel"
      strategy="idle"
      onLoad={() => console.log(`SUCCESS!`)}
    >
      {`
    (function(c,l,a,r,i,t,y){
			c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
			t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
			y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
	})(window, document, "clarity", "script", "${process.env.GATSBY_CLARITY_ID}");
`.trim()}
    </Script>
  );
};
